// npm modules
import React, {useState, useEffect, useContext, useRef} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { ShepherdTourContext } from 'react-shepherd'
import {withRouter} from "react-router-dom";
import { parse as parseQuery } from "query-string";

// styles
import styles from './clientHub.module.css';

// actions
import { setClientPathwayList } from "../../store/actions/pathwaysActions";
import {fetchFeaturedContent} from "../../store/actions/contentActions";
import {updateCoachNudge} from "../../store/actions/userActions";
import {tourUpdate} from "../../store/actions/userActions";
import {fetchDaxkoUser} from '../../store/actions/organizationActions';

// components
import TileTrack from "./tileTrack/tileTrack";
import {PrimaryLink} from "../links/links";
import {Modal} from "react-bootstrap";
import {PrimaryButton} from "../buttons/buttons";
import {TOURS} from "../../serverVariables/tours";
import {CONTENT_TYPES} from "../../serverVariables/contentTypes";

const ClientHub = props => {
    const tour = useContext(ShepherdTourContext);
    // props
    const { location, history } = props;
    // state
    const { user } = props;
    // actions
    const { setClientPathwayList, fetchDaxkoUser } = props;

    // local
    const [featured, setFeatured] = useState([]);
    const [showCoachModal, setShowCoachModal] = useState(false);
    const [daxkoScope] = useState(user.organization.daxko_org);
    const barcode = useRef(user.daxko_barcode);
    const [barcodeItem, setBarcodeItem] = useState(null);
    const [openThankyou, setOpenThankyou] = useState(false);

    // check if daxko connect variables are present on query string
    const daxkoCode = parseQuery(props.location.search).code;
    const daxkoState = parseQuery(props.location.search).state;

    // grab client pathway on mount
    useEffect(() => {
        setClientPathwayList();
    }, []);

    // grab featured content on mount
    useEffect(() => {
        fetchFeaturedContent()
            .then(content => {
                setFeatured(content);
                const first = content.find(item => item.content_type_id === CONTENT_TYPES.ORGANIZATION);
                if (first) setBarcodeItem(first.category_id);
            })
            .catch(e=>console.log(e))
    }, []);


    // start any tours
    useEffect(() => {
        const intro = user.tours.find(item => item.title === 'intro');
        if (!intro.viewed && !tour.isActive()){
            tour.start();
            tourUpdate(TOURS.HABITS)
                .catch(e => console.log(e));
        }

        if (location.state === 'start-tour' && !tour.isActive()) {
            tour.start();
            if (history.location.state) {
                history.replace({
                    ...history.location,
                    state: ''
                });
            }
        }
    }, []);

    // check if user has been given coach nudge
    useEffect(() => {
        if (!user || tour.isActive()) return;
        const nudge = user.coach_nudge;
        const intro = user.tours.find(item => item.title === 'intro');
        if (intro.viewed && !nudge && moment().isAfter(moment(user.start_date).add(24, 'hours'))) {
            setShowCoachModal(true);
            updateCoachNudge()
                .catch(e => console.log(e));
        }
    }, [user]);

    // check if page was loaded from a redirect from daxko and connect user
    useEffect(() => {
        if (daxkoCode && daxkoState && !barcode.current) {
            fetchDaxkoUser(daxkoCode)
                .then(res => {
                    barcode.current = res.user.daxko_barcode;
                    setOpenThankyou(true);
                })
                .catch(e => console.error(e));
        }
    }, [daxkoCode, daxkoState, user]);


    // wait for user to load into state
    if (!user || _.isEmpty(user)) {
        return (
            <div/>
        )
    }

    return (
        <div className="pos:r" id='clientHub'>
            <div className={styles.bulgeContainer}>
                <div className={styles.concave}/>
                <div className={styles.bulge}/>
            </div>
            <div className='container client-dash '>
                <div className='row'>
                    {featured.map((item, i) => {
                        return (
                            <div key={item.category_id} className="col-xs-12">
                                <div className={i === 0 ? 'tour-featured-intro':''}>
                                    <TileTrack
                                        barcode={i===0 && item.category_id === barcodeItem ? barcode.current : null}
                                        daxkoScope={i===0 && item.category_id === barcodeItem ? daxkoScope : null}
                                        openThankyou={i===0 && item.category_id === barcodeItem ? openThankyou : null}
                                        setOpenThankyou={setOpenThankyou}
                                        contentTypeId={item.content_type_id}
                                        icon={item.tile_image_url}
                                        title={item.category_name}
                                        subcategories={item.subcategories}
                                        content={item.content}
                                        dataCy={`habitTile_${i}`}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Modal show={showCoachModal} onHide={() => setShowCoachModal(false)}>
                    <div className={styles.coachOverlay}>
                        <h4>Talk with a personal wellness coach!</h4>
                        <p>You are five times more likely to achieve your health goals with the support of a personal wellness coach! Ready to book your free coach consultation?</p>
                        <div className={styles.btns}>
                            <PrimaryLink to={`/member/clientDash/${user.username}/Coaching-Team/coaches`}>Book Now!</PrimaryLink>
                            <PrimaryButton onClick={() => setShowCoachModal(false)}>Maybe Later</PrimaryButton>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
};

function mapStateToProps({ user, clientPathwayList, dailyScore }) {
    return { user, clientPathwayList, dailyScore };
}

const actions = {
    setClientPathwayList,
    fetchDaxkoUser
};


export default withRouter(connect(mapStateToProps, actions)(ClientHub));
