// npm modules
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

// components
import { ResponsiveVimeoIframe } from "../iframes/iframes";

const ClientPathwayNextSteps = ({ user }) => {
	const [ showiOSModal, setiOSShowModal ] = useState(false);
	const [ showAndroidModal, setAndroidShowModal ] = useState(false);

    return (
        <div className="container">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">        		      
				<div className="content-container">
					<h1 className="margin-bottom-3x">Next steps to success</h1>   
					<div className="success-container">
						<div className="step-number">1</div>
						<div className="step-text">
							<h4 className="no-margin">Install the Treo webapp</h4>
							<p>For iOS devices, use the “add to home screen" option on your web browser and add Treo as an app for easy access. For Android devices, use the “install web app” option on the browser menu.</p>
						</div>
					</div>

					<div className="success-container">
						<div className="step-number">2</div>
						<div className="step-text">
							<h4 className="no-margin">Explore the content and features</h4>
							<p>Learn how to use different parts of the Treo platform with virtual tutorials. Access these tours by clicking the More menu, then selecting Help & Tutorials.</p>
						</div>
					</div>

					<div className="success-container">
						<div className="step-number">3</div>
						<div className="step-text">
							<h4 className="no-margin">Message your coach for free</h4>
							<p>Your Certified Wellness Coach can customize your Habit Tiles, provide accountability, and connect you with resources to build sustainable healthy habits for free. </p>
						</div>
					</div>

					
				</div>
			</div>

			<Modal show={showiOSModal} onHide={() => setiOSShowModal(false)}>
				<Modal.Header closeButton/>
				<Modal.Body>
						<ResponsiveVimeoIframe src='441430938'/>
				</Modal.Body>
			</Modal>
	
			<Modal show={showAndroidModal} onHide={() => setAndroidShowModal(false)}>
				<Modal.Header closeButton/>
				<Modal.Body>
						<ResponsiveVimeoIframe src='441430984'/>
				</Modal.Body>				
			</Modal>
	
        </div>
    )
};

export default ClientPathwayNextSteps;